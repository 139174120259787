import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from "react-router-dom";
import { CartContext } from '../context/CartContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './Navbar.css';

const Navbar = ({ handleNavbarToggle, handleSidebarToggle, isNavbarOpen, handleMenuItemClick }) => {
    const { getTotalQuantity } = useContext(CartContext);
    const { cart } = useContext(CartContext);
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState('');

    useEffect(() => {
        setActiveMenu(location.pathname);
    }, [location]);

    useEffect(() => {
        const cursol = document.querySelector(".cursol");

        const handleMouseMove = (e) => {
            cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
        };

        const handleMouseEnter = () => {
            cursol.classList.add("hover-effect");
        };

        const handleMouseLeave = () => {
            cursol.classList.remove("hover-effect");
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
            element.addEventListener("mouseenter", handleMouseEnter);
            element.addEventListener("mouseleave", handleMouseLeave);
        });

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
                element.removeEventListener("mouseenter", handleMouseEnter);
                element.removeEventListener("mouseleave", handleMouseLeave);
            });
        };
    }, []);

    useEffect(() => {
        const cursol2 = document.querySelector(".cursol2");

        const handleMouseMove = (e) => {
            cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
        };

        const handleMouseEnter = () => {
            cursol2.classList.add("hover-effect");
        };

        const handleMouseLeave = () => {
            cursol2.classList.remove("hover-effect");
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
            element.addEventListener("mouseenter", handleMouseEnter);
            element.addEventListener("mouseleave", handleMouseLeave);
        });

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
                element.removeEventListener("mouseenter", handleMouseEnter);
                element.removeEventListener("mouseleave", handleMouseLeave);
            });
        };
    }, []);

    return (
        <nav className="navbar container-nav navbar-expand-lg navbar-light fixed-top">
            <div className='heading-container'>
                <Link to='/' className='heading-container' onClick={() => handleMenuItemClick('/')}>
                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1712129337/1_ozrowa.png"
                        className="Borgani-logo"
                        alt='logo'
                    />
                    <h1 className='borgnanics-heading'>B Organics</h1>
                </Link>
            </div>
            <div className='topleft-nav-container'>
                <button className="navbar-toggler" type="button" onClick={handleNavbarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <button className="sidebar-toggle" type="button" onClick={handleSidebarToggle}>
                    <span className="navbar-toggler-icon"></span>
                </button>                
                <Link to="/addcart" className='linkforAdcart mobile-cart-icon'>
                    <div className='CartPlus-length'>
                        {cart.length > 0 && <span className="Mobile-cart-count">{getTotalQuantity()}</span>}                 
                        <FontAwesomeIcon className="cart-icon" icon={faCartPlus} />
                    </div>
                </Link>
            </div>
            <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`}>
                <ul className="navbar-nav ml-auto">
                    <li className={`nav-item ${activeMenu === '/' ? 'active' : ''}`}>
                        <Link to="/" className="nav-link" onClick={() => handleMenuItemClick('/')}>Home</Link>
                    </li>
                    <li className={`nav-item dropdown Shop-Categories ${activeMenu.includes('/honeyproductstwo') || activeMenu.includes('/CoffeeProducts2') ? 'active' : ''}`}>
                        <button className="btn dropdown-toggle Shop-Categories nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                            Shop
                        </button>
                        <ul className="dropdown-menu dropdown-menu-light">
                            <Link to="/honeyproductstwo" className="dropdown-item" onClick={() => handleMenuItemClick('/honeyproductstwo')}>Honey Products</Link>
                            <Link to="/CoffeeProducts2" className="dropdown-item" onClick={() => handleMenuItemClick('/CoffeeProducts2')}>Coffee Products</Link>
                            <Link to="/all-products" className="dropdown-item" onClick={() => handleMenuItemClick('/all-products')}>All Products</Link>
                        </ul>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className={`nav-item ${activeMenu === '/contactus' ? 'active' : ''}`}>
                        <Link to='/contactus' className="nav-link" onClick={() => handleMenuItemClick('/contactus')}>Contact</Link>
                    </li>
                    <li className={`nav-item ${activeMenu === '/aboutus' ? 'active' : ''}`}>
                        <Link to='/aboutus' className="nav-link" onClick={() => handleMenuItemClick('/aboutus')}>About Us</Link>
                    </li>
                    
                </ul>                
            </div>
            <div className='userlogin-navbar'>
                <div className='icons_container'>
                    <Link to="/" className='linkforAddcat'>
                        <FontAwesomeIcon className="cart-icon" icon={faUser} />
                    </Link>
                    <Link to="/addcart" className='linkorAddcart'>
                        <div className='CartPlus-length'>
                            {cart.length > 0 && <span className="car-count">{getTotalQuantity()}</span>}
                            <FontAwesomeIcon className="cart-icon" icon={faCartPlus} />
                        </div>
                    </Link>
                </div>
            </div>
            <div>
                <Link to="/addcart" className='linkorAddcart'>
                    <div className='CartPlus-length'>
                        {cart.length > 0 && <span className="car-count">{getTotalQuantity()}</span>}
                        <FontAwesomeIcon className="cart-icon" icon={faCartPlus} />
                    </div>
                </Link>
            </div>
            <div className="cursol"></div>
            <div className="cursol2"></div>
        </nav>
    );
};

export default Navbar;
