import React, { useState } from 'react';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

const Header = () => {
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const handleNavbarToggle = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleMenuItemClick = (path) => {
        setIsNavbarOpen(false);
        setIsSidebarOpen(false);
    };

    return (
        <>
            <Navbar 
                handleNavbarToggle={handleNavbarToggle} 
                handleSidebarToggle={handleSidebarToggle} 
                isNavbarOpen={isNavbarOpen} 
                handleMenuItemClick={handleMenuItemClick} 
            />
            <Sidebar 
                isSidebarOpen={isSidebarOpen} 
                handleSidebarToggle={handleSidebarToggle} 
                handleMenuItemClick={handleMenuItemClick} 
            />
        </>
    );
};

export default Header;
