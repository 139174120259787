import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import 'animate.css';
import './index.css'; // Ensure this path is correct

const HoneyProductsTwo = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const honeyProducts = [
        {
            name: 'Wild Forest Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/3_hxrhgi.jpg',
            path: '/wildforesthoney',
            price: 'Rs 75.00',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Good source of antioxidants',
                'Raw honey nutrition',
                'Anti-bacterial & anti-fungal properties',
                'Healing wounds',
                'Phytonutrients powerhouse',
                'Help for digestive issues',
                'Soothe a sore throat and cough',
                'Brain benefits'
            ]
        },
        {
            name: 'Acacia Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/4_g803yw.jpg',
            path: '/acaciahoney',
            price: 'Rs 75.00',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Healing wounds',
                'Suitable for people - sensitive sugar',
                'Reduces body weight',
                'Healing wounds',
                'Good for gut ecology',
                'Skin care',
                'Helpful to hypoglycemia',
                'Aging, Cancer and variety of diseases'
            ]
        },
        {
            name: 'Moringa Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/2_tbtdul.jpg',
            path: '/moringahoney',
            price: 'Rs 75.00',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Reduces pigment controls blemishes',
                'Detoxify the gut',
                'Arthritis pain',
                'Surpasses cancer cells',
                'Throat infections',
                'Reduces ulcers',
                'Effective cough and throat remedy'
            ]
        }
    ];

    return (
        <>
            <div className='HoneyProductsTwo-container'>
                <div className="HoneyProductsTwo-main-title-container2">
                    <h1 className='HoneyProductsTwo-products-heading2'>Honey Products</h1>
                </div>
                <div className='HoneyProductsTwo-products-container2'>
                    {honeyProducts.map((product, index) => (
                        <div className='HoneyProductsTwo-product-container' key={index}>
                            <Link to={product.path} className='HoneyProductsTwo-clear_underline'>
                                <div className="HoneyProductsTwo-card">
                                    <div className="HoneyProductsTwo-image-container">
                                        <img src={product.imgSrc} className="HoneyProductsTwo-card-img-top HoneyProductsTwo-image" alt={product.name} />
                                        <div className="HoneyProductsTwo-overlay">
                                            <div className="HoneyProductsTwo-overlay-text">{product.name}</div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <h5 className='HoneyProductsTwo-heading'>{product.name}</h5>
                                        <p className="HoneyProductsTwo-card-text"><span className='HoneyProductsTwo-Available'>Available: </span> {product.availableSizes}</p>
                                        <p className='HoneyProductsTwo-pricetext'>{product.price}</p>
                                        <Link to={product.path}>
                                            <button className='btn btn-primary HoneyProductsTwo-btn_buybutton'>Buy Now</button>
                                        </Link>
                                    </div>
                                </div>
                            </Link>
                            <div className={`HoneyProductsTwo-benefits-card ${index === honeyProducts.length - 1 ? 'emptyBOXmargin-bottom' : ''} animate__animated animate__swing`}>
                                <div className='HoneyProductsTwo-BenefitsContainer'>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                    <h5 className='HoneyProductsTwo-Benefits-heading'>Benefits</h5>                                
                                </div>                                
                                <ul className='HoneyProductsTwo-benefits-list'>
                                    {product.benefits.map((benefit, idx) => (
                                        <li key={idx}>{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default HoneyProductsTwo;
