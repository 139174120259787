import React, { useEffect, useState } from 'react';
import './addcartcss.css';
import axios from 'axios';

const OrdersfromAddcart = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get('https://borganics-backend-code.onrender.com/api/ordercarts');
                setOrders(response.data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
    }, []);

    return (
        <div className="orders-container">
            <h2 className="orders-heading">Multi Orders</h2>
            <table className="orders-table">
                <thead className="orders-thead">
                    <tr>
                        <th className='th_name'>Full Name</th>
                        <th className='th_name'>Email</th>
                        <th className='th_name'>Phone</th>
                        <th className='th_name'>Address</th>
                        <th className='th_name'>Landmark</th>
                        <th className='th_name'>City</th>
                        <th className='th_name'>State</th>
                        <th className='th_name'>Pin</th>
                        <th className='th_name'>Cart</th>
                        {/* <th className='th_name'>Status</th> */}
                    </tr>
                </thead>
                <tbody className="orders-tbody">
                    {orders.map((order) => (
                        <tr key={order._id} className="orders-row">
                            <td className='th_name'>{order.fullName}</td>
                            <td className='th_name'>{order.email}</td>
                            <td className='th_name'>{order.phone}</td>
                            <td className='th_name'>{order.address}</td>
                            <td className='th_name'>{order.landmark}</td>
                            <td className='th_name'>{order.city}</td>
                            <td className='th_name'>{order.state}</td>
                            <td className='th_name'>{order.pin}</td>
                            <td className='th_name'>
                                <ul className="orders-cart">
                                    {order.cart.map((item, index) => (
                                        <li key={index} className="orders-cart-item">
                                            {item.name} - {item.size} - {item.price} - Qty: {item.quantity}
                                        </li>
                                    ))}
                                </ul>
                            </td>
                            {/* <td className="orders-status">{order.status}</td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrdersfromAddcart;
