import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './index.css';

const CoffeeProduct = () => {
    useEffect(() => {
        const preventRightClick = (e) => {
            e.preventDefault();
        };
        window.addEventListener('contextmenu', preventRightClick);
        return () => {
            window.removeEventListener('contextmenu', preventRightClick);
        };
    }, []);

    const coffeeProducts = [
        {
            name: 'Arabic AAA',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg',
            path: '/Arabicacoffee',
            price: 'Rs 165.99',
            availableSizes: '50gm, 100gm, 250gm',
            Benifits:[
                "- Rich Flavor Profile", 
                "Higher Antioxidant Content", 
                "Lower Caffeine Content", 
                "Improved Cognitive Function", 
                "Supports Heart Health", 
                "Aids in Weight Management", 
                "Environmental Benefits"
            ]
        },
        {
            name: 'Robusta AAA',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/2_euz06q.jpg',
            path: '/RobustaCoffee',
            price: 'Rs 155.00',
            availableSizes: '50gm, 100gm, 250gm',
            Benifits:[
                "- Higher Caffeine Content", 
                "Richer in Antioxidants", 
                "Distinctive Flavor Profile", 
                "Versatility in Blends", 
                "Improved Physical Performance", 
                "Neurological Benefits"
            ]
        },
    ];

    return (
        <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9 }}
        >
            <div className='mt-3'>
                <div className='Coffeeproducts-HoneyMain-Container'>
                    <div className="Coffeeproducts-Honey-main-title-container mt-5">
                        <h1 className='Coffeeproducts-Honey-products-heading'>Coffee Products</h1>
                    </div>
                    <div className='Coffeeproducts-products-container'>
                        {coffeeProducts.map((product, index) => (
                            <Link to={product.path} className='Coffeeproducts-clear_underline' key={index}>
                                <div className='Coffeeproducts-product-card'>
                                    <div className="Coffeeproducts-card">
                                        <div className="Coffeeproducts-container">
                                            <div>
                                                <img src={product.imgSrc} className="Coffeeproducts-image" alt={product.name} />
                                            </div>
                                            <div className="Coffeeproducts-overlay">
                                                <div className="Coffeeproducts-text">
                                                    <p className='listofbenifits'>
                                                        {product.Benifits}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Coffeeproducts-card-body">
                                            <h5 className='Coffeeproducts-Honey-heading'>{product.name}</h5>
                                            <p className="Coffeeproducts-card-text"><span className='Coffeeproducts-Available'>Available: </span> {product.availableSizes}</p>
                                            <div className='Coffeeproducts-buttons-container'>
                                                <p className='Coffeeproducts-pricetext'>{product.price}</p>
                                                <Link to={product.path}>
                                                    <button className='btn btn-success Coffeeproducts-btn_buybutton'>Buy Now</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default CoffeeProduct;
