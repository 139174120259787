import React, { useState, useEffect, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { CartContext } from '../context/CartContext';
import { Link, useLocation } from "react-router-dom";
import './Sidebar.css';

const Sidebar = ({ isSidebarOpen, handleSidebarToggle, handleMenuItemClick }) => {
    const { cart } = useContext(CartContext);
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState('');
    const [isDropup, setIsDropup] = useState(false);

    useEffect(() => {
        setActiveMenu(location.pathname);
    }, [location]);

    return (
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
            {/* <button className="close-btn" onClick={handleSidebarToggle}>&times;</button> */}
            <ul className="sidebar-nav">
                <li className={`nav-item ${activeMenu === '/' ? 'active' : ''}`}>
                    <Link to="/" className="nav-link" onClick={() => handleMenuItemClick('/')}>Home</Link>
                </li>
                <li className={`nav-item dropdown ${activeMenu.includes('/honeyproductstwo') || activeMenu.includes('/CoffeeProducts2') ? 'active' : ''}`}>
                    <button className="btn dropdown-toggle nav-link" onClick={() => setIsDropup(!isDropup)}>Shop</button>
                    <ul className={`dropdown-menu ${isDropup ? 'show' : ''}`}>                       
                        <li>
                            <Link to="/honeyproductstwo" className="dropdown-item" onClick={() => handleMenuItemClick('/honeyproductstwo')}>Honey Products</Link>
                            <Link to="/CoffeeProducts2" className="dropdown-item" onClick={() => handleMenuItemClick('/CoffeeProducts2')}>Coffee Products</Link>
                            <Link to="/all-products" className="dropdown-item" onClick={() => handleMenuItemClick('/all-products')}>All Products</Link>
                        </li>
                    </ul>
                </li>
                <li className={`nav-item ${activeMenu === '/contactus' ? 'active' : ''}`}>
                    <Link to='/contactus' className="nav-link" onClick={() => handleMenuItemClick('/contactus')}>Contact</Link>
                </li>
                <li className={`nav-item ${activeMenu === '/aboutus' ? 'active' : ''}`}>
                    <Link to='/aboutus' className="nav-link" onClick={() => handleMenuItemClick('/aboutus')}>About Us</Link>
                </li>
            </ul>            
        </div>
    );
};

export default Sidebar;
