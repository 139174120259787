import React, { useState, useContext } from 'react';
import { CartContext } from '../context/CartContext';
import './CheckCart.css';
import { useNavigate } from 'react-router-dom';

const CheckoutCart = () => {
    const { selectedProducts, clearCart, getTotal } = useContext(CartContext);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        address: '',
        landmark: '',
        city: '',
        state: '',
        pin: '',
    });

    const [formErrors, setFormErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            setIsLoading(true);
            try {
                const response = await fetch('https://borganics-backend-code.onrender.com/api/ordercarts', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...formData,
                        cart: selectedProducts,
                        status: 'placed',
                    }),
                });

                if (response.ok) {
                    clearCart();
                    alert('Order placed successfully!');
                    navigate('/');
                } else {
                    const errorData = await response.json();
                    console.error('Failed to place order:', errorData);
                    alert(`Failed to place order: ${errorData.message}`);
                }
            } catch (error) {
                console.error('Error placing order:', error);
                alert('Error placing order. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleWhatsAppOrder = () => {
        const isValid = validateForm();
        if (isValid) {
            const orderDetails = `
                Full Name: ${formData.fullName}
                Email: ${formData.email}
                Phone: ${formData.phone}
                Address: ${formData.address}, ${formData.landmark}, ${formData.city}, ${formData.state}, ${formData.pin}
                Products: ${selectedProducts.map(product => `
                    ${product.name} - ${product.size} - Rs ${product.price} - Quantity: ${product.quantity}`).join('')}
                Total: Rs ${getTotal()}
            `;

            const whatsappURL = `https://api.whatsapp.com/send?phone=7845318719&text=${encodeURIComponent(orderDetails)}`;
            window.open(whatsappURL, '_blank');
        } else {
            alert('Please fill in all required fields before placing the order via WhatsApp.');
        }
    };

    const validateForm = () => {
        let errors = {};
        let isValid = true;

        if (!formData.fullName.trim()) {
            errors.fullName = 'Please enter your full name';
            isValid = false;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim() || !emailPattern.test(formData.email)) {
            errors.email = 'Please enter a valid email';
            isValid = false;
        }

        const phonePattern = /^[0-9]{10}$/;
        if (!formData.phone.trim() || !phonePattern.test(formData.phone)) {
            errors.phone = 'Please enter a valid phone number';
            isValid = false;
        }

        if (!formData.address.trim()) {
            errors.address = 'Please enter your address';
            isValid = false;
        }

        if (!formData.city.trim()) {
            errors.city = 'Please enter your city';
            isValid = false;
        }

        if (!formData.state.trim()) {
            errors.state = 'Please enter your state';
            isValid = false;
        }

        const pinPattern = /^[0-9]{6}$/;
        if (!formData.pin.trim() || !pinPattern.test(formData.pin)) {
            errors.pin = 'Please enter a valid PIN code';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    return (
        <div className="checkout-container">
            <h2 className="checkout-heading">Checkout</h2>
            <div className="checkout-content">
                <div className="checkout-products">
                    <ul>
                        {selectedProducts.map((item, index) => (
                            <li key={index}>
                                {item.name} - {item.size} - Rs {item.price} - Quantity: {item.quantity}
                            </li>
                        ))}
                    </ul>
                    <h3>Total: Rs {getTotal()}</h3>
                </div>
                <form className="checkout-form" onSubmit={handleSubmit}>
                    <label>
                        Full Name:
                        <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
                        {formErrors.fullName && <span className="error-message">{formErrors.fullName}</span>}
                    </label>
                    <label>
                        Email:
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                        {formErrors.email && <span className="error-message">{formErrors.email}</span>}
                    </label>
                    <label>
                        Phone No:
                        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
                        {formErrors.phone && <span className="error-message">{formErrors.phone}</span>}
                    </label>
                    <label>
                        Address:
                        <input type="text" name="address" value={formData.address} onChange={handleChange} required />
                        {formErrors.address && <span className="error-message">{formErrors.address}</span>}
                    </label>
                    <label>
                        Landmark:
                        <input type="text" name="landmark" value={formData.landmark} onChange={handleChange} />
                    </label>
                    <label>
                        City:
                        <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                        {formErrors.city && <span className="error-message">{formErrors.city}</span>}
                    </label>
                    <label>
                        State:
                        <input type="text" name="state" value={formData.state} onChange={handleChange} required />
                        {formErrors.state && <span className="error-message">{formErrors.state}</span>}
                    </label>
                    <label>
                        PIN Code:
                        <input type="text" name="pin" value={formData.pin} onChange={handleChange} required />
                        {formErrors.pin && <span className="error-message">{formErrors.pin}</span>}
                    </label>
                    <div className="place-order-cart">
                        <button type="submit" className="place-order-button" disabled={isLoading}>
                            {isLoading ? 'Placing Order...' : 'Place Order'}
                        </button>
                        <button type="button" className="place-order-button" onClick={handleWhatsAppOrder}>
                            Order With WhatsApp
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CheckoutCart;
