import React, { useContext } from 'react';
import { CartContext } from '../context/CartContext';
import './CartList.css'; 
import { useNavigate } from 'react-router-dom';

const CartList = () => {
    const { cart, removeItem, increaseQuantity, decreaseQuantity, getTotal, handleBuyNow } = useContext(CartContext);
    const navigate = useNavigate();

    const handleRemove = (index) => {
        removeItem(index);
    };

    const handleIncrease = (index) => {
        increaseQuantity(index);
    };

    const handleDecrease = (index) => {
        decreaseQuantity(index);
    };

    return (
        <div className="cart-container">
            <h2 className="cart-heading">Cart Items</h2>
            {cart.length === 0 ? (
                <div className='cartnoitem-container'>
                 <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1713193162/3960310_gb3v0a.jpg' 
                 className='Addcart'
                 alt='Addcart'/>
                <p className='empty-cart'>Your cart is empty.</p>
                </div>
            ) : (
                <>
                    <ul>
                        {cart.map((item, index) => (
                            <li key={index} className="cart-item">
                                <div className="cart-item-info">
                                    <span className="product-name">{item.name}</span>
                                    <span className="product-size">{item.size}</span>
                                    <span className="product-price">{item.price}</span>
                                    <span className="product-quantity">Quantity: {item.quantity}</span>
                                </div>
                                <div className="cart-item-controls">
                                    <button className="decrease-button" onClick={() => handleDecrease(index)}>-</button>
                                    <span className="product-quantity">{item.quantity}</span>
                                    <button className="increase-button" onClick={() => handleIncrease(index)}>+</button>
                                    <button className="remove-button" onClick={() => handleRemove(index)}>Remove</button>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="cart-summary">
                        <h3>Total: Rs {getTotal()}</h3>
                        <button onClick={handleBuyNow} className="button_BuyNow">
                            Buy Now
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default CartList;
