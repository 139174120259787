import React, { useEffect, useState } from 'react';
import Footer from '../Footer';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import './AllProduct.css'; // Ensure this path is correct

const All_Product = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isHovered, setIsHovered] = useState(false);

    const handleBuyClick = () => {
        // Your buy logic here
        console.log('Buy button clicked!');
    };


    const honeyProducts = [
        {
            name: 'Wild Forest Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/3_hxrhgi.jpg',
            path: '/wildforesthoney',
            price: 'Rs 75.00',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Good source of antioxidants',
                'Raw honey nutrition',
                'Anti-bacterial & anti-fungal properties',
                'Healing wounds',
                'Phytonutrients powerhouse',
                'Help for digestive issues',
                'Soothe a sore throat and cough',
                'Brain benefits'
            ]
        },
        {
            name: 'Acacia Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/4_g803yw.jpg',
            path: '/acaciahoney',
            price: 'Rs 75.00',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Healing wounds',
                'Suitable for people - sensitive sugar',
                'Reduces body weight',
                'Healing wounds',
                'Good for gut ecology',
                'Skin care',
                'Helpful to hypoglycemia',
                'Aging, Cancer and variety of diseases'
            ]
        },
        {
            name: 'Moringa Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/2_tbtdul.jpg',
            path: '/moringahoney',
            price: 'Rs 75.00',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Reduces pigment controls blemishes',
                'Detoxify the gut',
                'Arthritis pain',
                'Surpasses cancer cells',
                'Throat infections',
                'Reduces ulcers',
                'Effective cough and throat remedy'
            ]
        }
    ];

    const coffeeProducts = [
        {
            name: 'Arabic AAA',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg',
            path: '/Arabicacoffee',
            price: 'Rs 165.99',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Rich Flavor Profile',
                'Higher Antioxidant Content',
                'Lower Caffeine Content',
                'Improved Cognitive Function',
                'Supports Heart Health',
                'Aids in Weight Management',
                'Environmental Benefits'
            ]
        },
        {
            name: 'Robusta AAA',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/2_euz06q.jpg',
            path: '/RobustaCoffee',
            price: 'Rs 155.00',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Higher Caffeine Content',
                'Richer in Antioxidants',
                'Distinctive Flavor Profile',
                'Versatility in Blends',
                'Improved Physical Performance',
                'Neurological Benefits'
            ]
        }
    ];

    return (
        <>
            <div className='all-products-container'>
                <div className="main-title-container">
                    <h1 className='products-heading'>Products</h1>
                </div>
                <div className='products-wrapper'>
                    {[...honeyProducts, ...coffeeProducts].map((product, index) => (
                        <div className='product-card' key={index}>
                            <div className='product-container'>
                                <div className="product-back">
                                    <div className="benefits-container">
                                        <FontAwesomeIcon icon={faThumbtack} className="icon" />
                                        <h3 className='benefits-heading'>Benefits</h3>
                                        <ul className='benefits-list'>
                                            {product.benefits.map((benefit, idx) => (
                                                <li key={idx}>{benefit}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                                                 
                                <div className="product-front">
                                    <img src={product.imgSrc} className="product-image" alt={product.name} />
                                    <div className="product-details">
                                        <h5 className='product-name'>{product.name}</h5>
                                        <p className="product-price">{product.price}</p>
                                        <p className="product-sizes"><span className='available'>Available: </span>{product.availableSizes}</p>                                        
                                    </div>                                    
                                </div>                                
                            </div>
                         
                            <Link to={product.path} className='allproduct_buttonContainer mt-4'>
                            <button className={isHovered ? 'btn btn-primary btn-buy-button hover-animation' : 'btn btn-primary btn-buy-button'} onClick={handleBuyClick} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>Buy Now</button>                                
                            </Link>
            
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default All_Product;
