import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import './CheckoutPage.css';
import { motion } from 'framer-motion';

let honeyOrderCount = 0;
let coffeeOrderCount = 0;

const generateOrderId = (orderType) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');

  let orderId;
  let orderNumber;

  if (orderType === 'Honey') {
    honeyOrderCount++;
    orderNumber = honeyOrderCount.toString().padStart(5, '0');
    orderId = `${year}${month}H${orderNumber}`;
  } else if (orderType === 'Coffee') {
    coffeeOrderCount++;
    orderNumber = coffeeOrderCount.toString().padStart(5, '0');
    orderId = `${year}${month}C${orderNumber}`;
  } else {
    throw new Error('Invalid order type');
  }

  return orderId;
};

const CheckoutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const selectedProduct = location.state ? location.state.selectedProduct : {};

  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    address: '',
    landmark: '',
    city: '',
    state: '',
    pin: '',
    phone: '',
    paymentMethod: ''
  });

  const [formErrors, setFormErrors] = useState({
    fullname: '',
    email: '',
    address: '',
    landmark: '',
    city: '',
    state: '',
    pin: '',
    phone: '',
    paymentMethod: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      try {
        const orderType = selectedProduct.type === 'Honey' ? 'Honey' : 'Coffee';
        const orderId = generateOrderId(orderType);

        const response = await fetch('https://borganics-backend-code.onrender.com/api/place/order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            price: selectedProduct.price,
            product: selectedProduct.name,
            size: selectedProduct.size,
            quantity: selectedProduct.quantity,
            email: formData.email,
            paymentMethod: formData.paymentMethod,
            addr: formData.address,
            landmark: formData.landmark,
            city: formData.city,
            state: formData.state,
            pin: formData.pin,
            phone: formData.phone,
            username: formData.fullname,
            orderId: orderId,
            totalPrice: selectedProduct.totalPrice // Include total price here
          }),
        });

        if (response.ok) {
          const msg = await response.json();
          if (msg.msg === "orderCreated..") {
            console.log('Form submitted successfully');
            navigate('/Confimedorder');
          }
        } else {
          console.log('Form submission failed:', await response.json());
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('An error occurred. Please try again.');
      }
    } else {
      console.log('Form submission failed. Please fill in all required fields.');
      alert('Please fill in all required fields before placing the order.');
    }
  };

  const handleWhatsAppOrder = () => {
    const isValid = validateForm();
    if (isValid) {
      const orderType = selectedProduct.type === 'Honey' ? 'Honey' : 'Coffee';
      const orderId = generateOrderId(orderType);

      const orderDetails = `
        Order ID: ${orderId}
        Name: ${formData.fullname}
        Email: ${formData.email}
        Phone: ${formData.phone}
        Address: ${formData.address}, ${formData.landmark}, ${formData.city}, ${formData.state}, ${formData.pin}
        Product: ${selectedProduct.name}
        Size: ${selectedProduct.size}
        Quantity: ${selectedProduct.quantity}
        Total Price: ${selectedProduct.totalPrice}
      `;

      const whatsappURL = `https://api.whatsapp.com/send?phone=7845318719&text=${encodeURIComponent(orderDetails)}`;
      window.open(whatsappURL, '_blank');
    } else {
      console.log('WhatsApp order submission failed. Please fill in all required fields.');
      alert('Please fill in all required fields before placing the order via WhatsApp.');
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.fullname.trim()) {
      errors.fullname = 'Please enter your full name';
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = 'Please enter your email';
      isValid = false;
    }

    if (!formData.address.trim()) {
      errors.address = 'Please enter your address';
      isValid = false;
    }

    if (!formData.landmark.trim()) {
      errors.landmark = 'Please enter your landmark';
      isValid = false;
    }

    if (!formData.city.trim()) {
      errors.city = 'Please enter your city';
      isValid = false;
    }

    if (!formData.state.trim()) {
      errors.state = 'Please enter your state';
      isValid = false;
    }

    if (!formData.pin.trim()) {
      errors.pin = 'Please enter your PIN code';
      isValid = false;
    }

    if (!formData.phone.trim()) {
      errors.phone = 'Please enter your phone number';
      isValid = false;
    }

    setFormErrors(errors);

    return isValid;
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
      >
        <div className="CheckoutPage-checkout-container mt-5">       
          <div className="CheckoutPage-checkout-form-container">
            <h1 className="CheckoutPage-checkout-heading">Checkout</h1>
            <div className="selected-product-details">
              <h1 className='Selected-heading mb-5'>Selected Product Details:</h1>
              <p>Name: {selectedProduct.name}</p>
              <p>Size: {selectedProduct.size}</p>
              <p>Price: {selectedProduct.price}</p>
              <p>Quantity: {selectedProduct.quantity}</p>
              <p>Total Price: {selectedProduct.totalPrice}</p> {/* Display selected total price */}
            </div>          
            <form onSubmit={handleSubmit} className="checkoutpage-form">
              <div className="form-group checkoutpage-form-group">
                <label className="checkoutpage-label">Full Name</label>
                <input type="text" className="form-control checkoutpage-input" name="fullname" value={formData.fullname} onChange={handleChange} required />
                {formErrors.fullname && <span className="checkoutpage-error">{formErrors.fullname}</span>}
              </div>
              <div className="form-group checkoutpage-form-group">
                <label className="checkoutpage-label">Email</label>
                <input type="email" className="form-control checkoutpage-input" name="email" value={formData.email} onChange={handleChange} required />
                {formErrors.email && <span className="checkoutpage-error">{formErrors.email}</span>}
              </div>
              <div className="form-group checkoutpage-form-group">
                <label className="checkoutpage-label">Phone No</label>
                <input type="tel" className="form-control checkoutpage-input" name="phone" value={formData.phone} onChange={handleChange} required />
                {formErrors.phone && <span className="checkoutpage-error">{formErrors.phone}</span>}
              </div>
              <div className="form-group checkoutpage-form-group">
                <label className="checkoutpage-label">Address</label>
                <input type="text" className="form-control checkoutpage-input" name="address" value={formData.address} onChange={handleChange} required />
                {formErrors.address && <span className="checkoutpage-error">{formErrors.address}</span>}
              </div>
              <div className="form-group checkoutpage-form-group">
                <label className="checkoutpage-label">Landmark</label>
                <input type="text" className="form-control checkoutpage-input" name="landmark" value={formData.landmark} onChange={handleChange} required />
                {formErrors.landmark && <span className="checkoutpage-error">{formErrors.landmark}</span>}
              </div>
              <div className="form-group checkoutpage-form-group">
                <label className="checkoutpage-label">City</label>
                <input type="text" className="form-control checkoutpage-input" name="city" value={formData.city} onChange={handleChange} required />
                {formErrors.city && <span className="checkoutpage-error">{formErrors.city}</span>}
              </div>
              <div className="form-group checkoutpage-form-group">
                <label className="checkoutpage-label">State</label>
                <input type="text" className="form-control checkoutpage-input" name="state" value={formData.state} onChange={handleChange} required />
                {formErrors.state && <span className="checkoutpage-error">{formErrors.state}</span>}
              </div>
              <div className="form-group checkoutpage-form-group">
                <label className="checkoutpage-label">PIN Code</label>
                <input type="text" className="form-control checkoutpage-input" name="pin" value={formData.pin} onChange={handleChange} required />
                {formErrors.pin && <span className="checkoutpage-error">{formErrors.pin}</span>}
              </div>
              <div className='orderbutton-container'>
                <button type="submit" className="btn btn-primary checkoutpage-btn">Place Order</button>
                <button type="button" className="btn btn-secondary checkoutpage-btn" onClick={handleWhatsAppOrder}>Order with WhatsApp</button>
              </div>             
            </form>
          </div> 
          <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1715127644/Girl_image_tatgj5.png'
            className='Girl-image'
            alt='girlimage'/>
          <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1715130593/B%20organics/check-out_wttgfw.jpg'
            className='Checkout'
            alt='girlimage'/>
        </div>  
        <Footer />
      </motion.div>
    </>    
  );
};

export default CheckoutPage;
