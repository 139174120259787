import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faBowlFood, faEarthEurope, faUserShield } from '@fortawesome/free-solid-svg-icons';
import HoneyProducts from '../HoneyProducts';
import CoffeeProduct from '../CoffeeProducts';
import { Carousel } from 'react-bootstrap';
// import UserFeedback from '../Userfeedback';
import Footer from '../Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import Feedback from '../Feedbackobject';
import './index.css'; // Import the custom CSS file
import { isMobileOnly, isTablet } from 'react-device-detect'; // Import from react-device-detect
import Borganicsbag from './LandingpageImages/Borganics-bag.png';
import { Link } from 'react-router-dom';

const Landingpage = ({ history }) => {

    useEffect(() => {
        const handleMouseMove = (e) => {
            const cursol = document.querySelector(".cursol");
            const cursol2 = document.querySelector(".cursol2");

            if (cursol && cursol2) {
                cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
                cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
            }
        };

        const handleMouseEnter = () => {
            const cursol = document.querySelector(".cursol");
            if (cursol) cursol.classList.add("hover-effect");
        };

        const handleMouseLeave = () => {
            const cursol = document.querySelector(".cursol");
            if (cursol) cursol.classList.remove("hover-effect");
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
            element.addEventListener("mouseenter", handleMouseEnter);
            element.addEventListener("mouseleave", handleMouseLeave);
        });

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
                element.removeEventListener("mouseenter", handleMouseEnter);
                element.removeEventListener("mouseleave", handleMouseLeave);
            });
        };
    }, []);

    return (
        <>
            <div className="honey-products">
            <div className="header-section">
                <Carousel>
                    <Carousel.Item>
                        <div className="container-fluid">
                            <div className="ads-container">
                                <div className="col-6 header-content">
                                    <h1 className='ads-heading'>Honey</h1>
                                    <p className='Honey-ads'>Experience Nature's Sweetest Gift. Our 100% Pure, Raw Honey is Harvested Straight 
                                        from the Hive. Taste the Authentic Flavor of Nature.
                                        Sweeten Your Life Naturally. Rich in Antioxidants and Packed with Nutrients, Our Honey is Not 
                                        Just Delicious – It’s Good for You Too.</p>
                                    <Link to="/honeyproductstwo"><button className="btn btn-primary" href="#">Buy Now</button></Link>
                                </div>
                                <div className="header-image">
                                    {/* <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1717848063/B%20organics/Ads-honey_crcpn3.png" alt="Honey" className="img-fluid" /> */}
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="container-fluid">
                            <div className="ads-container">
                                <div className="col-6 header-content">
                                    <h1 className='ads-heading'>Coffee</h1>
                                    <p className='Honey-ads'>Awaken Your Senses with Every Sip. Discover the Rich, Bold Flavor of Our Coffee. 
                                        Perfectly Roasted, Expertly Crafted. Taste the Difference.
                                        From Bean to Cup: Experience the Pinnacle of Coffee Perfection. Sourced from the Finest Plantations, 
                                        Roasted to Perfection, Delivered to You.</p>
                                    <Link to="/CoffeeProducts2"><button className="btn btn-primary" href="#">Buy Now</button></Link>
                                </div>
                                <div className="header-image">
                                    {/* <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1717848075/B%20organics/ads-coffee_d77sth.png" alt="Coffee" className="img-fluid" /> */}
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="container-fluid">
                            <div className="ads-container">
                                <div className="col-6 header-content">
                                    <h1 className='ads-headingtwo'>Fresh Products</h1>
                                    <p className='Honey-ads'>Revitalize Your Senses with Each Sip of Our 100% Natural Coffee, 
                                        and Delight in the Pure Sweetness of Our 100% Natural Honey. 
                                        Experience the Perfect Harmony of Expertly Roasted Coffee and Pure, Raw Honey.</p>
                                    <Link to="/all-products"><button className="btn btn-primary" href="#">Click All</button></Link>
                                </div>
                                <div className="header-image">
                                    <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1713450311/B%20organics/100-Natural-PNG-Photo_mmn2tr.png" alt="Fresh Fruits" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
                <div className='app-container'>
                    <div>
                        <div className='Products-container'>
                            <div className='HoneyAndCoffee_Components'>
                                <HoneyProducts />
                                <CoffeeProduct />
                            </div>
                            <div className='container'>
                                <div className='row'>
                                    {/* Add any additional content here */}
                                </div>
                            </div>
                            <div className="Landingpage-whoweare2">
                                <div className='Landingpage-whoweare-top'>
                                    <div className='image-rule'>
                                        {/* <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1717131049/100-natural-nutrion-healthy-eating-life-2_aon78r.jpg"
                                            className='organicsimage'
                                            alt='ProductProccess' /> */}
                                    </div>
                                </div>
                                <div className='Landingpage-whoweare'>
                                    <div className='icons-container animate__animated animate__fadeInLeft'>
                                        <div className='whoweare-container marginself'>
                                            <div className='insidecontainer'>
                                                <FontAwesomeIcon icon={faLeaf} className='who-icons' />
                                                <p className='whowe-are-para'>Indulge in the purity of our farm-fresh offerings, lovingly grown without pesticides (or) harmful chemicals, embodying our commitment to providing you with the freshest and safest produce possible.</p>
                                            </div>
                                        </div>
                                        <div className='whoweare-container'>
                                            <div className='insidecontainer'>
                                                <FontAwesomeIcon icon={faBowlFood} className='who-icons' />
                                                <p className='whowe-are-para'>Our selection offers a delicious blend of taste and nutrition, Indulge in our flavorful options, Experience a symphony of flavors and nutrients in every bite with our meticulously selected offerings.</p>
                                            </div>
                                        </div>
                                        <div className='whoweare-container'>
                                            <div className='insidecontainer'>
                                                <FontAwesomeIcon icon={faEarthEurope} className='who-icons' />
                                                <p className='whowe-are-para'>With a focus on scientific innovation, our farming methods are dedicated to nurturing biodiversity. We place a premium on sustainable agriculture, striving to protect and improve the environment for future generations.</p>
                                            </div>
                                        </div>
                                        <div className='whoweare-container'>
                                            <div className='whowere-icons insidecontainer'>
                                                <FontAwesomeIcon icon={faUserShield} className='who-icons' />
                                                <p className='whowe-are-para'>With a focus on your well-being, our products sourced from dedicated farmers are both healthy and biologically safe, meeting stringent quality standards for your peace of mind.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='Landingpage-whoweare-top-2 row col-12'>
                                    {/* <div className='whyusheadeing2 col-12 col-md-6 col-xl-12 row'>
                                        <div className='col-12 col-md-6 col-xl-6 product-details'>
                                            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1713450311/B%20organics/Fresh-PNG-Free-Image_gua0lm.png'
                                                className='Freshself'
                                                alt='img-landing' />
                                            <h5 className='landing-cycle-heading'>100% Fresh Products</h5>
                                            <p className='whyus-para'>Our selection is carefully cultivated using sustainable farming practices, 
                                            Elevate your senses with our collection of 100% fresh products.each item is a testament to our commitment to 
                                            providing you with nature's finest offerings, From vibrant fruits to crisp vegetables, indulge in the pure goodness 
                                            of freshness with every bite.</p>
                                        </div>
                                        <div className='col-12 col-md-6 col-xl-6 product-details'>
                                            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1713450311/B%20organics/100-Natural-PNG-Photo_mmn2tr.png'
                                                className='whyusimage'
                                                alt='img-landing' />
                                            <h5 className='landing-cycle-heading'>Natural Products</h5>
                                            <p className='whyus-para whyus-para2'>Embark on a journey of discovery as you explore the pure essence of nature with our exquisite
                                                range of 100% natural organic products. Carefully selected and lovingly crafted, each item embodies the timeless beauty 
                                                of Mother Earth. From the lush greenery of sprawling fields to the gentle caress of sun-kissed fruits.</p>
                                        </div>
                                    </div> */}
                                    <div className='whyus-image-comtainer col-xl-12 col-12 col-md-6 row'>
                                        {/* <div className='col-12 col-md-6 col-xl-4 product-details'>
                                            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1713450311/B%20organics/healthy-eating_wgpk0n.png'
                                                className='whyusimage'
                                                alt='img-landing' />
                                            <h5 className='landing-cycle-heading'>Healthy Eat</h5>
                                            <p className='whyus-para'>We think nature is really powerful for making us feel healthy and happy. That's why we carefully pick and make each thing in our collection, always thinking about how it can help you stay healthy.</p>
                                        </div>
                                        <div className='col-12 col-md-6 col-xl-4 product-details2'>
                                            <img src={Borganicsbag}
                                                className='whyusimage-bag'
                                                alt='img-landing' />
                                        </div>
                                        <div className='col-12 col-md-6 col-xl-4 product-details'>
                                            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1713450311/B%20organics/qnfs1_y1xq6x.png'
                                                className='whyusimage'
                                                alt='img-landing' />
                                            <h5 className='landing-cycle-heading'>Product Quality</h5>
                                            <p className='whyus-para whyus-para2'>We spare no effort in ensuring that every product maintains its pristine quality and integrity. With our steady commitment to quality assurance, you can trust that every purchase embodies the pinnacle of excellence.</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='userfeedback_container'>
                                {/* <UserFeedback /> */}
                                <Feedback />
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Landingpage;
