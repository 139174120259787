import React, { useState } from 'react';
import './index.css';

const feedbacks = [
    {
        text: "Best quality. it's so fresh and tasty too.for the 1st time I've tasted such authentic and tasted honey with proper testing and I've compared too with normal honey which I purchased from market,it's really unique flavour and tasty...so guys just go through this and enjoy the purity",
        name: "Uma"
    },
    {
        text: "Honey is very sweet and tasty..i remembering childhood days..",
        name: "Madhu mathi"
    },
    {
        text: "Each spoonful is a journey through the untamed beauty of the forest, leaving a lingering sensation of pure, unadulterated bliss. A true treasure of the wild, this honey captivates both the palate and the soil.",
        name: "Durga Vara prasad"
    },
    {
        text: "Its actually better than renowned brands ,and the taste remains for sometime.",
        name: "Avinash"
    },
    {
        text: "The honey was really so tasty and Tangy.!! In my childhood I had pure forest honey and the same experience/Taste I had with B Organics after plenty of years.. !! It is 100% pure.. Thickness of the honey really extent.",
        name: "Kumar"
    }
    // Add more feedback objects here
];

const colors = ['#4CAF50', '#FF5722', '#2196F3', '#FFC107', '#9C27B0']; 

const Feedback = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const getInitial = (name) => {
        return name.charAt(0).toUpperCase();
    };

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + feedbacks.length) % feedbacks.length);
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % feedbacks.length);
    };

    return (
        <div className="feedback-carousel-container">
            <h5 className='m-5'>Customer Reviews</h5>
            <div className="feedback-carousel-container2">
                <div className="feedback-carousel">
                    <button className="carousel-control left" onClick={goToPrevious}>&#10094;</button>
                    <div className="feedback-item">
                        <div className="feedback-text">
                            <p style={{fontSize:"15px"}}>{feedbacks[currentIndex].text}</p>
                            <h4>{feedbacks[currentIndex].name}</h4>
                        </div>
                        <div
                            className="feedback-initial" 
                            style={{ backgroundColor: colors[currentIndex % colors.length] }}
                        >
                            {/* <p className='profilename'>{getInitial(feedbacks[currentIndex].name)}</p> */}
                        </div>
                    </div>
                    <button className="carousel-control right" onClick={goToNext}>&#10095;</button>
                </div>
                <div className="carousel-controls">
                    {feedbacks.map((_, index) => (
                        <span key={index} className={`dot ${index === currentIndex ? 'active' : ''}`}></span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Feedback;
