// import React, { useEffect, useState, useContext } from 'react';
// import Footer from '../../Footer';
// import { useNavigate } from 'react-router-dom';
// import RecommendProduct from '../../RecommendProduct';
// import { CartContext } from '../../context/CartContext'; // Import CartContext
// import { motion } from 'framer-motion';
// import './index.css';

// const Coffee_Arabico50 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg";
// const Coffee_Arabico100 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg";
// const Coffee_Arabico250 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg";

// const HoneyBuypage = () => {
//   useEffect(() => {
//     const cursol = document.querySelector(".cursol");
//     const cursol2 = document.querySelector(".cursol2");

//     const handleMouseMove = (e) => {
//         cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
//         cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
//     };

//     const handleMouseEnter = () => {
//         cursol.classList.add("hover-effect");
//     };

//     const handleMouseLeave = () => {
//         cursol.classList.remove("hover-effect");
//     };

//     document.addEventListener("mousemove", handleMouseMove);
//     document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
//         element.addEventListener("mouseenter", handleMouseEnter);
//         element.addEventListener("mouseleave", handleMouseLeave);
//     });

//     return () => {
//         document.removeEventListener("mousemove", handleMouseMove);
//         document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
//             element.removeEventListener("mouseenter", handleMouseEnter);
//             element.removeEventListener("mouseleave", handleMouseLeave);
//         });
//     };
//   }, []);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const navigate = useNavigate();
//   const [selectedSize, setSelectedSize] = useState('250g');
//   const { addToCart } = useContext(CartContext); // Access addToCart function from CartContext

//   const handleSizeChange = (event) => {
//     setSelectedSize(event.target.value);
//   };

//   const handleBuyNow = () => {
//     const selectedProduct = {
//       name: 'Arabic AAA',
//       size: selectedSize,
//       price: getPrice(selectedSize),
//     };
//     navigate('/Arabicacoffee/checkoutpage', { state: { selectedProduct } });
//   };

//   const handleAddToCart = () => {
//     const selectedProduct = {
//       name: 'Arabic AAA',
//       size: selectedSize,
//       price: getPrice(selectedSize),
//     };
//     addToCart(selectedProduct); // Add selected product to the cart
//     console.log("Product added:", selectedProduct);
//   };

//   const getPrice = (size) => {
//     switch (size) {
//       case '50g':
//         return 'Rs 165.99';
//       case '100g':
//         return 'Rs 290.99';
//       case '250g':
//         return 'Rs 690.99';
//       default:
//         return '';
//     }
//   };

//   const getImageUrl = (size) => {
//     switch (size) {
//       case '50g':
//         return Coffee_Arabico50;
//       case '100g':
//         return Coffee_Arabico100;
//       case '250g':
//         return Coffee_Arabico250;
//       default:
//         return '';
//     }
//   };

//   return (
//     <>
//       <motion.div
//         initial={{ opacity: 5, y: -20 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.9 }}
//       >
//         <div className='Coffee-product-container-Ara'>
//           <div className='container mt-5'>
//             <div className='row'>
//               <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex  justify-content-center">
//                 <div className="product-bottle">
//                   <div className="product-card-container">
//                     <img
//                       src={getImageUrl(selectedSize)}
//                       className='Honey-product' alt="Honey-product"
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5 Hhhhhhh justify-content-center'>
//                 <h1 className="name-for-prduct mt-5" style={{color:'white'}}>Arabic AAA</h1>
//                 <div>
//                   {selectedSize && (
//                     <p className='select-container3'>size : {selectedSize}<br/>Price : {getPrice(selectedSize)}</p>             
//                   )}
//                 </div>
//                 <div className='prodcut-size-card'>
//                   <label htmlFor="size" className='onlycolor5'>Size:</label>
//                   <select id="size" className='select-container select-container2' value={selectedSize} onChange={handleSizeChange}>
//                     <option value="50g">50 gm</option>
//                     <option value="100g">100 gm</option>
//                     <option value="250g">250 gm</option>
//                   </select>
//                 </div>
//                 <div className='button_container'>
//                   <button className='button_BuyNow' onClick={handleBuyNow}>Buy Now</button>
//                   <button className='button_BuyNow' onClick={handleAddToCart}>Add to Cart</button>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <hr className='hrClass'/>
//           <h1 className="menu-section-heading mb-2 mt-5">Recommendation</h1>
//           <div className="explore-menu-section2" id="exploreMenuSection">
//             <div className="cardcenter">   
//               <RecommendProduct
//                 imageSrc={Coffee_Arabico50}
//                 name="Arabic AAA-50g"
//                 price="Rs 165.99"
//                 size="50g"
//               />
//               <RecommendProduct
//                 imageSrc={Coffee_Arabico100}
//                 name="Arabic AAA-100g"
//                 price="Rs 290.99"
//                 size="100g"
//               />
//               <RecommendProduct
//                 imageSrc={Coffee_Arabico250}
//                 name="Arabic AAA-250g"
//                 price="Rs 690.99"
//                 size="250g"
//               />
//             </div>
//           </div>
//           <Footer />
//         </div>
//       </motion.div>
//       <div className="cursol"></div>
//       <div className="cursol2"></div>
//     </>
//   );
// };

// export default HoneyBuypage;


//updated Quantity total price
import React, { useEffect, useState, useContext } from 'react';
import Footer from '../../Footer';
import { useNavigate } from 'react-router-dom';
import RecommendProduct from '../../RecommendProduct';
import ProductQuality from '../../QualityProduct';
import { motion } from 'framer-motion';
import { CartContext } from '../../context/CartContext'; 
import './index.css';

const Arabica_coffee50 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg";
const Arabica_coffee100 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg";
const Arabica_coffee250 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg";

const extraImages = [
  
  {
    src: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg',
    alt: 'Designer Image 1'
  },
  {
    src: 'https://res.cloudinary.com/drevfgyks/image/upload/v1717957893/B%20organics/1_q9lu9o.jpg',
    alt: 'Designer Image 2'
  },
 
  {
    src: 'https://res.cloudinary.com/drevfgyks/image/upload/v1717958677/B%20organics/Designer_15_oxtott.png',
    alt: 'Designer Image 9'
  },
  {
    src: 'https://res.cloudinary.com/drevfgyks/image/upload/v1717957893/B%20organics/2_ciopyf.jpg',
    alt: 'Designer Image 5'
  }
];

const getPrice = (size) => {
  switch (size) {
    case '50g':
      return 'Rs 165.99';
    case '100g':
      return 'Rs 290.99';
    case '250g':
      return 'Rs 690.99';
    default:
      return '';
  }
};

const getImageUrl = (size) => {
  switch (size) {
    case '50g':
      return Arabica_coffee50;
    case '100g':
      return Arabica_coffee100;
    case '250g':
      return Arabica_coffee250;
    default:
      return '';
  }
};

const HoneyBuypage = () => {
  useEffect(() => {
    const cursol = document.querySelector(".cursol");
    const cursol2 = document.querySelector(".cursol2");

    const handleMouseMove = (e) => {
      cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
      cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
    };

    const handleMouseEnter = () => {
      cursol.classList.add("hover-effect");
    };

    const handleMouseLeave = () => {
      cursol.classList.remove("hover-effect");
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
      element.addEventListener("mouseenter", handleMouseEnter);
      element.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [selectedSize, setSelectedSize] = useState('250g');
  const [quantity, setQuantity] = useState(1); // Initialize quantity state with 1
  const [totalPrice, setTotalPrice] = useState(getPrice('250g')); // Initialize total price state
  const { addToCart } = useContext(CartContext); // Access addToCart function from CartContext

  const [mainImage, setMainImage] = useState(getImageUrl('250g')); // State for main image

  useEffect(() => {
    // Update total price when quantity or selected size changes
    const price = parseFloat(getPrice(selectedSize).replace('Rs ', '')) * quantity;
    setTotalPrice(`Rs ${price.toFixed(2)}`);
    setMainImage(getImageUrl(selectedSize)); // Update main image when size changes
  }, [selectedSize, quantity]);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
    setQuantity(1); // Reset quantity when size changes
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value));
  };

  const handleAddToCart = () => {
    const selectedProduct = {
      name: 'Arabic AAA',
      size: selectedSize,
      price: getPrice(selectedSize), // Ensure price remains a string
      quantity: quantity, // Include selected quantity
    };
    addToCart(selectedProduct); // Add selected product to the cart
    console.log("Product added:", selectedProduct);
  };

  const handleBuyNow = () => {
    const selectedProduct = {
      name: 'Arabic AAA',
      size: selectedSize,
      price: getPrice(selectedSize), // Ensure price remains a string
      quantity: quantity, // Include selected quantity
      totalPrice: totalPrice, // Include total price
    };
    navigate('/Arabicacoffee/checkoutpage', { state: { selectedProduct } });
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 5, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
      >
        <div className='Arabica_coffee-product-container'>
          <div className='Arabica_coffee_selected_product_container'>
            <div className='row Arabica_coffee_marignsTop'>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex  justify-content-center">
                <div className="Arabica_coffee_product-bottle">
                  <div className="Arabica_coffee_product-card-container ">
                    <img
                      src={mainImage}
                      className='Arabica_coffee_Honey-product' alt="Arabica coffee product"
                    />
                    <div className='Arabica_coffee_extra-images-thumbnails'>
                      {extraImages.map((image, index) => (
                        <img 
                          key={index} 
                          src={image.src} 
                          alt={image.alt} 
                          className='Arabica_coffee_extra-image-thumbnail' 
                          onClick={() => setMainImage(image.src)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 Arabica_coffee_product_details-container justify-content-center'>
                <h1 className="Arabica_coffee_product_name" style={{ color: 'rgb(206, 206, 206)', fontSize:'32px'}}>Arabic AAA</h1>
                <div className='Arabica_coffee_product-description'>
                  {selectedSize && (
                    <p className='Arabica_coffee_select-container3'>Size: {selectedSize}<br/>{getPrice(selectedSize)}</p>             
                  )}
                </div>
                <div className='Arabica_coffee_prodcut-size-card'>
                  <label htmlFor="size" className='Arabica_coffee_onlycolor5'>Size :</label>
                  <select id="size" className='Arabica_coffee_select-container' value={selectedSize} onChange={handleSizeChange}>
                    <option value="50g">50 gm</option>
                    <option value="100g">100 gm</option>
                    <option value="250g">250 gm</option>
                  </select>
                </div>
                <div className='Arabica_coffee-product-quantity-card'>
                  <label htmlFor="quantity" className='Arabica_coffee-label'></label>
                  <select id="quantity" className='Arabica_coffee-select' value={quantity} onChange={handleQuantityChange}>
                    {[...Array(10).keys()].map(n => (
                      <option key={n + 1} value={n + 1}>Quantity : {n + 1}</option>
                    ))}
                  </select>
                </div>
                <div className='Arabica_coffee_button_container '>
                  <button className='Arabica_coffee_button_BuyNow' onClick={handleBuyNow}>Buy Now</button>
                  <button className='Arabica_coffee_button_AddCart' onClick={handleAddToCart}>Add to Cart</button>
                </div>
              </div>
            </div>
          </div>
          <hr className='Arabica_coffee_hrClass'/>
          <div className='Arabica_coffee_ProductQuality-conrainer1'>
            <h4 className="Arabica_coffee_quality_borganics">Quality of B Organics</h4>
            <ProductQuality/>
          </div>
          <Footer />
        </div>
      </motion.div>
    </>
  );
};

export default HoneyBuypage;
