import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import './index.css';

const HoneyProducts = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const honeyProducts = [
        {
            name: 'Wild Forest Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/3_hxrhgi.jpg',
            path: '/wildforesthoney',
            price: 'Rs 75.00',
            availableSizes: '50gm, 100gm, 250gm',
            l:"- Good source of antioxidants, Raw honey nutrition, Anti-bacterial and anti-fungal properties, Healing wounds, Phytonutrients powerhouse, Help for digestive issues, Soothe a sore throat and cough, Brain benefits"
        },
        {
            name: 'Acacia Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/4_g803yw.jpg',
            path: '/acaciahoney',
            price: 'Rs 75.00',
            availableSizes: '50gm, 100gm, 250gm',
            l:"- Healing wounds, Suitable for people - sensitive sugar, Reduces body weight, Healing wounds, Good for gut ecology, Skin care, Helpful to hypoglycemia, Aging, Cancer & variety of diseases"
        },
        {
            name: 'Moringa Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/2_tbtdul.jpg',
            path: '/moringahoney',
            price: 'Rs 75.00',
            availableSizes: '50gm, 100gm, 250gm',
            l:"- Reduces pigmentation & controls blemishes, Anti-Bacterial, anti-fungal, anti-microbial to combat infections, Detoxify the gut, Arthritis pain, Surpasses cancer cells, Throat infections, Reduces ulcers, Excellent cough & throat infection medicine"
        },
    ];

    return (
        <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9 }}
            className="Honeyproducts-honey-products"
        >
            <div className='Honeyproducts-HoneyMain-Container'>
                <div className="Honeyproducts-Honey-main-title-container">
                    <h1 className='Honeyproducts-Honey-products-heading'>Honey Products</h1>
                </div>
                <div className='Honeyproducts-products-container'>
                    {honeyProducts.map((product, index) => (
                        <Link to={product.path} className='Honeyproducts-clear_underline' key={index}>
                            <div className='Honeyproducts-product-card'>
                                <div className="Honeyproducts-card">
                                    <div className="Honeyproducts-container">
                                        <div>
                                            <img src={product.imgSrc} className="Honeyproducts-image" alt={product.name} />
                                        </div>
                                        <div className="Honeyproducts-overlay">
                                            <div className="Honeyproducts-text"><p className='listofbenifits'>{product.l}</p></div>
                                        </div>
                                    </div>
                                    <div className="Honeyproducts-card-body">
                                        <h5 className='Honeyproducts-Honey-heading'>{product.name}</h5>
                                        <p className="Honeyproducts-card-text"><span className='Honeyproducts-Available'>Available: </span> {product.availableSizes}</p>
                                        <div className='Honeyproducts-buttons-container'>
                                            <p className='Honeyproducts-pricetext'>{product.price}</p>
                                            <Link to={product.path}>
                                                <button className='Honeyproducts-btn Honeyproducts-btn-success Honeyproducts-btn_buybutton'>Buy Now</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </motion.div>
    );
};

export default HoneyProducts;


