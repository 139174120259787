import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const navigate = useNavigate();

  const addToCart = (product) => {
    const existingProductIndex = cart.findIndex(
      (item) => item.name === product.name && item.size === product.size
    );

    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += product.quantity; // Add the selected quantity
      setCart(updatedCart);
    } else {
      setCart([...cart, { ...product, quantity: product.quantity }]);
    }
  };

  const removeItem = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
  };

  const increaseQuantity = (index) => {
    const updatedCart = [...cart];
    updatedCart[index].quantity += 1;
    setCart(updatedCart);
  };

  const decreaseQuantity = (index) => {
    const updatedCart = [...cart];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1;
    } else {
      updatedCart.splice(index, 1);
    }
    setCart(updatedCart);
  };

  const clearCart = () => {
    setCart([]);
  };

  const getTotal = () => {
    return cart.reduce((total, item) => {
      const price = parseFloat(item.price.replace('Rs ', ''));
      return total + price * item.quantity;
    }, 0).toFixed(2);
  };

  const getTotalQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const handleBuyNow = () => {
    setSelectedProducts(cart);
    navigate('/checkoutcart');
  };

  return (
    <CartContext.Provider value={{ cart, selectedProducts, addToCart, removeItem, increaseQuantity, decreaseQuantity, clearCart, getTotal, getTotalQuantity, handleBuyNow }}>
      {children}
    </CartContext.Provider>
  );
};
