import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import 'animate.css';
import './index.css';

const CoffeeProductTwo = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const coffeeProducts = [
        {
            name: 'Arabic AAA',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg',
            path: '/Arabicacoffee',
            price: 'Rs 165.99',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Rich Flavor Profile',
                'Higher Antioxidant Content',
                'Lower Caffeine Content',
                'Improved Cognitive Function',
                'Supports Heart Health',
                'Aids in Weight Management',
                'Environmental Benefits'
            ]
        },
        {
            name: 'Robusta AAA',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/2_euz06q.jpg',
            path: '/RobustaCoffee',
            price: 'Rs 155.00',
            availableSizes: '50gm, 100gm, 250gm',
            benefits: [
                'Higher Caffeine Content',
                'Richer in Antioxidants',
                'Distinctive Flavor Profile',
                'Versatility in Blends',
                'Improved Physical Performance',
                'Neurological Benefits'
            ]
        }
    ];

    return (
        <>
            <div className='coffeeProductTwo-container'>
                <div className="coffeeProductTwo-main-title-container2">
                    <h1 className='coffeeProductTwo-products-heading2'>Coffee Products</h1>
                </div>
                <div className='coffeeProductTwo-products-container2'>
                    {coffeeProducts.map((product, index) => (
                        <div className='coffeeProductTwo-product-container' key={index}>
                            <Link to={product.path} className='coffeeProductTwo-clear_underline'>
                                <div className="coffeeProductTwo-card">
                                    <div className="coffeeProductTwo-image-container">
                                        <img src={product.imgSrc} className="coffeeProductTwo-card-img-top coffeeProductTwo-image" alt={product.name} />
                                        <div className="coffeeProductTwo-overlay">
                                            <div className="coffeeProductTwo-overlay-text">{product.name}</div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <h5 className='coffeeProductTwo-heading'>{product.name}</h5>
                                        <p className="coffeeProductTwo-card-text"><span className='coffeeProductTwo-Available'>Available: </span>{product.availableSizes}</p>
                                        <p className='coffeeProductTwo-pricetext'>{product.price}</p>
                                        <Link to={product.path}>
                                            <button className='btn btn-primary coffeeProductTwo-btn_buybutton'>Buy Now</button>
                                        </Link>
                                    </div>
                                </div>
                            </Link>
                            <div className={`coffeeProductTwo-benefits-card ${index === coffeeProducts.length - 1 ? 'coffeeProductTwo-emptyBOXmargin-bottom' : ''} animate__animated animate__swing`}>
                                <div className='coffeeProductTwo-BenefitsContainer'>
                                    <FontAwesomeIcon icon={faThumbtack} />
                                    <h5 className='coffeeProductTwo-Benefits-heading'>Benefits</h5>
                                </div>
                                <ul className='coffeeProductTwo-benefits-list'>
                                    {product.benefits.map((benefit, idx) => (
                                        <li key={idx}>{benefit}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CoffeeProductTwo;
