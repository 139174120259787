import React from 'react';
import './WhatsAppContactButton.css';
import whatsappIcon from './whatsapp-icon.png'; // Make sure to add the WhatsApp icon image in the same directory
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Whatsapp = () => {
  return (
    <a href="https://wa.me/7845318719" className="whatsapp-button" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon"/>
      {/* WhatsApp */}
    </a>
  );
};

export default Whatsapp;
