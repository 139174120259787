import './Termsandconditions.css'; // Import your CSS file
import React, { useEffect } from 'react';


const Termsandconditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the window to the top when the component mounts
    }, []);
    return(
        <div className="Termsandconditionscontainer container">
            <div className="Termsandconditionsrow row col-12 mt-5 p-5">
                <h1 className="Termsandconditionsh1 mt-5">Terms and Conditions</h1>
                <p className="Termsandconditionsp">Welcome to B Organics, your destination for premium organic honey and coffee products. By accessing our website and purchasing our products, you agree to comply with and be bound by the following terms and conditions of use. Please read these terms carefully before making a purchase.</p>
                <p className="Termsandconditionsp">"B Organics" refers to our company, which operates the e-commerce platform offering organic honey and coffee products."Customer," "you," and "your" refer to the user or purchaser of our products."Products" refer to the organic honey and coffee products available for sale on our website.</p>
                <h3 className="Termsandconditionsh3">Product Information</h3>
                <p className="Termsandconditionsp">We strive to provide accurate descriptions and images of our products; however, we cannot guarantee that colors and details will be accurately represented on every device.</p>
                <h3 className="Termsandconditionsh3">Product Damage</h3>
                <p className="Termsandconditionsp"><span className='spacialclass'>Overview:</span><br/>We take great care to ensure that your products are packaged securely and delivered safely. However, we understand that sometimes items can be damaged during transit. The following terms and conditions outline our policies regarding product damage during delivery.
                    <br/><span className='spacialclass'>Reporting Damages:</span> <br/>1. Immediate Inspection : Upon receiving your order, please inspect the packaging for any signs of damage. If you notice any issues, we recommend opening the package in the presence of the delivery person, if possible.
                    <br/>2. Report Damage Within 48 Hours : If you find that any product is damaged, you must report it to us within 48 hours of delivery. Please include your order number, a description of the damage, and photos of the damaged product and packaging.
                    <br/><span className='spacialclass'>How to Report:</span> <br/>To report a damaged product, please contact our Customer Support team through one of the following methods:
                    <br/>- Email : info@borganics.in<br/>- Whatsapp : 7845318719
                    <br/><span className='spacialclass'>Evaluation and Resolution :</span> <br/>1. Once we receive your report, our team will review the information and assess the damage and unboxing video is must for refund.
                    <br/>2. Replacement or Refund : Depending on the assessment, we will offer you either a replacement of the damaged product or a full refund. Replacements are subject to product availability.
                    <br/>3. Return Instructions : In some cases, we may request that you return the damaged product to us. If this is necessary, we will provide you with a prepaid return shipping label.
                    <br/><span className='spacialclass'>Conditions :</span> <br/>1. Original Packaging : The damaged product must be returned in its original packaging, including any accessories, manuals, and documentation.
                    <br/>2. Non-Compliance : Failure to comply with the reporting timeline or return instructions may result in the denial of a replacement or refund.
                    <br/><span className='spacialclass'>Exclusions :</span> <br/>1. Minor Damages : Minor cosmetic damages that do not affect the product's functionality are not covered under this policy.
                    <br/>2. Third-Party Sellers : Products purchased from third-party sellers or other retailers are not covered under our damage policy. Please refer to the seller's own policies.
                </p>
                <h3 className="Termsandconditionsh3">Pricing and Payment</h3>
                <p className="Termsandconditionsp">All prices are listed in Rupees and are subject to change without notice. Payment for orders must be made in full at the time of purchase. We accept upi, Debit Cards, Credit Carts.</p>
                <h3 className="Termsandconditionsh3">Shipping and Delivery</h3>
                <p className="Termsandconditionsp">We are pleased to inform you that our delivery services are available 365 days a year, from 5:00 AM to 10:00 PM. No matter the day, you can count on us to deliver your products promptly within these hours.</p>
                <p className="Termsandconditionsp">We aim to process and ship orders promptly. However, shipping times may vary depending on your location and product availability. We are not responsible for delays caused by shipping carriers or customs</p>
                <h3 className="Termsandconditionsh3">Returns and Refunds</h3>
                <p className="Termsandconditionsp">We stand behind the quality of our products. If you are not satisfied with your purchase, please contact us within 2 days of receiving your order to arrange for a return or exchange. Please note that certain conditions may apply and unboxing video is must for refund.</p>
                <h3 className="Termsandconditionsh3">Privacy Policy</h3>
                <p className="Termsandconditionsp">We respect your privacy and are committed to protecting your personal information. Our Privacy Policy outlines how we collect, use, and safeguard your data. By using our website, you consent to our Privacy Policy.</p>
                <h3 className="Termsandconditionsh3">Intellectual Property</h3>
                <p className="Termsandconditionsp">All content on our website, including logos, images, and text, is the property of B Organics and is protected by intellectual property laws. You may not use, reproduce, or distribute any content without our prior written consent.</p>
                <h3 className="Termsandconditionsh3">Limitation of Liability</h3>
                <p className="Termsandconditionsp">In no event shall B Organics be liable for any indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our products or website.</p>
                <h3 className="Termsandconditionsh3">Governing Law</h3>
                <p className="Termsandconditionsp">These terms and conditions shall be governed by and construed in accordance with the laws of india.</p>
                <h3 className="Termsandconditionsh3">Updates to Terms and Conditions</h3>
                <p className="Termsandconditionsp">We reserve the right to update or modify these terms and conditions at any time without prior notice. Continued use of our website and purchase of our products constitutes acceptance of any changes.</p>
                <p className="Termsandconditionsp">By making a purchase on our website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>
            </div>
        </div>
    )
}

export default Termsandconditions;
