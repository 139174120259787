import React, { useState, useEffect } from 'react';
import CustomerOrders from '../CustomerOrders';
import OrdersfromAddcart from '../OrdersfromAddcart'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import './index.css'; // Import CSS file

const AdminDashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // State for loading spinner

  useEffect(() => {
    // Check for login cookie
    const token = Cookies.get('adminToken');
    if (token) {
      setIsLoggedIn(true);
      startLogoutTimer(); // Start the logout timer if the user is already logged in
    }
  }, []);

  // Function to handle admin login
  const handleLogin = async () => {
    if (!username || !password) {
      setError('Both username and password are required');
      return;
    }

    setLoading(true); // Show spinner
    setError(''); // Clear previous error
    try {
      const response = await axios.post('https://borganics-backend-code.onrender.com/api/admin/login', {
        username,
        password
      });
      if (response.status === 200) {
        Cookies.set('adminToken', response.data.token, { expires: 1 }); // Set cookie for 1 day
        setIsLoggedIn(true);
        startLogoutTimer(); // Start the logout timer after successful login
      } else {
        setError('Invalid credentials');
      }
    } catch (error) {
      setError('Login failed');
      console.error('Login failed:', error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  // Function to start the logout timer
  const startLogoutTimer = () => {
    setTimeout(() => {
      handleLogout();
    }, 21600000); // Logout after 6 hours (21600000 milliseconds)
  };

  // Function to handle admin logout
  const handleLogout = () => {
    Cookies.remove('adminToken'); // Remove the cookie
    setIsLoggedIn(false);
  };

  return (
    <div className='main-container'>
      <div className="">
        {isLoggedIn ? (
          <div>
            <h1><CustomerOrders isLoggedIn={isLoggedIn} onLogout={handleLogout} /></h1>
            <h1><OrdersfromAddcart isLoggedIn={isLoggedIn} onLogout={handleLogout} /></h1>
          </div>
        ) : (
          <div className='admininputs AdminDashboard_container1'>
            {loading ? (
              <div className="spinner"></div>
            ) : (
              <>
                <FontAwesomeIcon icon={faUserTie} size="3x" className='admin-logo' />
                <input
                  type="text"
                  placeholder="Username"
                  className='Inputs'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  className='Inputs'
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button className="AdminDashboard_button" onClick={handleLogin}>Login</button>
                {error && <p className="AdminDashboard_error">{error}</p>}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
