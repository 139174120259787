import React, { useEffect, useState, useContext } from 'react';
import Footer from '../../Footer';
import { useNavigate } from 'react-router-dom';
import RecommendProduct from '../../RecommendProduct';
import ProductQuality from '../../QualityProduct';
import { motion } from 'framer-motion';
import { CartContext } from '../../context/CartContext'; 
import './index.css';

const Wildforest_Honey50 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/2_tbtdul.jpg";
const Wildforest_Honey100 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/2_tbtdul.jpg";
const Wildforest_Honey250 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/2_tbtdul.jpg";

const extraImages = [
  
  {
    src: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/2_tbtdul.jpg',
    alt: 'Designer Image 1'
  },
  {
    src: 'https://res.cloudinary.com/drevfgyks/image/upload/v1717956551/B%20organics/Designer_13_ebfovu.png',
    alt: 'Designer Image 2'
  },
  {
    src: 'https://res.cloudinary.com/drevfgyks/image/upload/v1717920516/B%20organics/Designer_8_xlg3pd.png',
    alt: 'Designer Image 3'
  },
  {
    src: 'https://res.cloudinary.com/drevfgyks/image/upload/v1717920381/B%20organics/Designer_9_yj2bz3.png',
    alt: 'Designer Image 9'
  },
  {
    src: 'https://res.cloudinary.com/drevfgyks/image/upload/v1717956549/B%20organics/Designer_14_ftaakl.png',
    alt: 'Designer Image 5'
  }
];

const getPrice = (size) => {
  switch (size) {
    case '50g':
      return 'Rs 75.00';
    case '100g':
      return 'Rs 155.00';
    case '250g':
      return 'Rs 410.00';
    default:
      return '';
  }
};

const getImageUrl = (size) => {
  switch (size) {
    case '50g':
      return Wildforest_Honey50;
    case '100g':
      return Wildforest_Honey100;
    case '250g':
      return Wildforest_Honey250;
    default:
      return '';
  }
};

const HoneyBuypage = () => {
  useEffect(() => {
    const cursol = document.querySelector(".cursol");
    const cursol2 = document.querySelector(".cursol2");

    const handleMouseMove = (e) => {
      cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
      cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
    };

    const handleMouseEnter = () => {
      cursol.classList.add("hover-effect");
    };

    const handleMouseLeave = () => {
      cursol.classList.remove("hover-effect");
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
      element.addEventListener("mouseenter", handleMouseEnter);
      element.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
        element.removeEventListener("mouseenter", handleMouseEnter);
        element.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [selectedSize, setSelectedSize] = useState('250g');
  const [quantity, setQuantity] = useState(1); // Initialize quantity state with 1
  const [totalPrice, setTotalPrice] = useState(getPrice('250g')); // Initialize total price state
  const { addToCart } = useContext(CartContext); // Access addToCart function from CartContext

  const [mainImage, setMainImage] = useState(getImageUrl('250g')); // State for main image

  useEffect(() => {
    // Update total price when quantity or selected size changes
    const price = parseFloat(getPrice(selectedSize).replace('Rs ', '')) * quantity;
    setTotalPrice(`Rs ${price.toFixed(2)}`);
    setMainImage(getImageUrl(selectedSize)); // Update main image when size changes
  }, [selectedSize, quantity]);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
    setQuantity(1); // Reset quantity when size changes
  };

  const handleQuantityChange = (event) => {
    setQuantity(parseInt(event.target.value));
  };

  const handleAddToCart = () => {
    const selectedProduct = {
      name: 'Moringa Honey',
      size: selectedSize,
      price: getPrice(selectedSize), // Ensure price remains a string
      quantity: quantity, // Include selected quantity
    };
    addToCart(selectedProduct); // Add selected product to the cart
    console.log("Product added:", selectedProduct);
  };

  const handleBuyNow = () => {
    const selectedProduct = {
      name: 'Moringa Honey',
      size: selectedSize,
      price: getPrice(selectedSize), // Ensure price remains a string
      quantity: quantity, // Include selected quantity
      totalPrice: totalPrice, // Include total price
    };
    navigate('/moringahoney/checkoutpage', { state: { selectedProduct } });
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 5, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
      >
        <div className='moringa-product-container'>
          <div className='moringa_selected_product_container'>
            <div className='row moringa_marignsTop'>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex  justify-content-center">
                <div className="moringa_product-bottle">
                  <div className="moringa_product-card-container ">
                    <img
                      src={mainImage}
                      className='moringa_Honey-product' alt="moringa Honey product"
                    />
                    <div className='moringa_extra-images-thumbnails'>
                      {extraImages.map((image, index) => (
                        <img 
                          key={index} 
                          src={image.src} 
                          alt={image.alt} 
                          className='moringa_extra-image-thumbnail' 
                          onClick={() => setMainImage(image.src)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 moringa_product_details-container justify-content-center'>
                <h1 className="moringa_product_name" style={{ color: 'black', fontSize:'32px'}}>Moringa Honey</h1>
                <div className='moringa_product-description'>
                  {selectedSize && (
                    <p className='moringa_select-container3'>Size: {selectedSize}<br/>{getPrice(selectedSize)}</p>             
                  )}
                </div>
                <div className='moringa_prodcut-size-card'>
                  <label htmlFor="size" className='moringa_onlycolor5'>Size :</label>
                  <select id="size" className='moringa_select-container' value={selectedSize} onChange={handleSizeChange}>
                    <option value="50g">50 gm</option>
                    <option value="100g">100 gm</option>
                    <option value="250g">250 gm</option>
                  </select>
                </div>
                <div className='moringa-product-quantity-card'>
                  <label htmlFor="quantity" className='moringa-label'></label>
                  <select id="quantity" className='moringa-select' value={quantity} onChange={handleQuantityChange}>
                    {[...Array(10).keys()].map(n => (
                      <option key={n + 1} value={n + 1}>Quantity : {n + 1}</option>
                    ))}
                  </select>
                </div>
                <div className='moringa_button_container '>
                  <button className='Honey_button_BuyNow' onClick={handleBuyNow}>Buy Now</button>
                  <button className='Honey_button_AddCart' onClick={handleAddToCart}>Add to Cart</button>
                </div>
              </div>
            </div>
          </div>
          <hr className='moringa_hrClass'/>
          <div className='moringa_ProductQuality-conrainer1'>
            <h4 className="moringa_quality_borganics">Quality of B Organics</h4>
            <ProductQuality/>
          </div>
          <Footer />
        </div>
      </motion.div>
    </>
  );
};

export default HoneyBuypage;
